.ant-typography h1,
.ant-typography h2,
.ant-typography h3,
.ant-typography h4,
.ant-typography h5,
h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography {
  font-weight: 500;
}

.ant-input-prefix {
  margin-right: 8px;
}

.ant-input-suffix {
  margin-left: 8px;
}

.ant-steps {
  padding: 11px 0;
}

.ant-card-body {
  padding: 16px;
}

.ant-select-item-option-state {
  display: none;
}

.ant-picker-time-panel-column::after {
  display: none;
}

.ant-radio-group {
  font-size: unset;
}
