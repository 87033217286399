// -------- import official style files -----------
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";
@import "../node_modules/@ctrl/ngx-emoji-mart/picker.css";

// -------- override NZ-ZORRO styles -----------
@import "styles/overrides/overrides.less";
@import "styles/overrides/antd-overrides.less";
@import "styles/overrides/antd-overrides-mobile.less";

@border-radius-base: 8px;
@checkbox-border-radius: 4px;
@tag-border-radius: 4px;

// -------- override default styles -----------
body {
  background-color: #FAFAFA;
  touch-action: pan-y pan-x;
  -webkit-text-size-adjust: 100%;
}
